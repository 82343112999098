<div class="container my-4">
  <div class="col-md-8 mt-3 mx-auto">
    <div class="my-4 text-center">Please choose 4 workshops according to your preference.</div>

    <form (ngSubmit)="save()" [formGroup]="form" novalidate>
      <input type="hidden" formControlName="group_id" />
      <div class="alert alert-danger" [hidden]="!error || isLoading" translate>
        {{ error }}
      </div>
      <div class="row">
        <div class="text-center col-sm-6" *ngFor="let item of config?.workshop_defination; let i = index">
          <div class="form-check form-group">
            <input
              type="checkbox"
              class="form-check-input"
              formControlName="workshop_{{ item.id }}"
              id="workshop{{ i }}"
            />
            <label class="form-check-label" for="workshop{{ i }}">{{ item.label }}</label>
          </div>
        </div>
      </div>

      <button class="btn btn-primary w-100" type="submit" [disabled]="form.invalid || isLoading">
        <span translate>Save</span>
      </button>
    </form>
  </div>
</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
